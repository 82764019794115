/* stylelint-disable custom-property-pattern */
@use '../../node_modules/@royalaholddelhaize/design-system-pantry-web/design-tokens.css' as *;
@use './fonts/greycliff' as *;

@layer reset, pantry, defaults, allerhande;

@layer reset {
    * {
        box-sizing: border-box;
    }
    
    *,
    ::before,
    ::after {
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
    
    html,
    body {
        padding: 0;
        margin: 0;
        text-size-adjust: 100%;
    }
}

@layer defaults {
    body {
        font-family: var(--typography-body-regular-desktop-fontFamily);
        color: var(--color-foreground-primary-default);
        background-color: var(--color-background-primary-default);
    }    
}

// Create local custom brand
@layer allerhande {
    $font-family: Greycliff, "Helvetica Neue", Helvetica, Arial, sans-serif;

    :is([data-brand='allerhande']) {
        --color-action-primary-default: #ea3d3d;
        --color-action-primary-disabled: #ea3d3d;
        --color-action-primary-hover: #e96666;
        --color-action-primary-pressed: #e96666;
        --color-action-secondary-default: #000;
        --color-action-secondary-disabled: #000;
        --color-action-secondary-hover: #333;
        --color-action-secondary-pressed: #333;
        --color-background-secondary-default: #f7f7f7;
        --color-background-secondary-hover: #e0e0e0;
        --color-background-inverted-default: #131313;
        --color-background-quaternary-default: #acacac;
        --color-background-quinary-default: #595959;
        --typography-display-desktop-fontFamily: #{$font-family};
        --typography-display-tablet-fontFamily: #{$font-family};
        --typography-display-mobile-fontFamily: #{$font-family};
        --typography-heading-1-desktop-fontFamily: #{$font-family};
        --typography-heading-1-tablet-fontFamily: #{$font-family};
        --typography-heading-1-mobile-fontFamily: #{$font-family};
        --typography-heading-2-desktop-fontFamily: #{$font-family};
        --typography-heading-2-tablet-fontFamily: #{$font-family};
        --typography-heading-2-mobile-fontFamily: #{$font-family};
        --typography-heading-3-desktop-fontFamily: #{$font-family};
        --typography-heading-3-tablet-fontFamily: #{$font-family};
        --typography-heading-3-mobile-fontFamily: #{$font-family};
        --typography-body-regular-desktop-fontFamily: #{$font-family};
        --typography-body-regular-tablet-fontFamily: #{$font-family};
        --typography-body-regular-mobile-fontFamily: #{$font-family};
        --typography-body-strong-desktop-fontFamily: #{$font-family};
        --typography-body-strong-tablet-fontFamily: #{$font-family};
        --typography-body-strong-mobile-fontFamily: #{$font-family};
        --typography-body-extra-strong-desktop-fontFamily: #{$font-family};
        --typography-body-extra-strong-tablet-fontFamily: #{$font-family};
        --typography-body-extra-strong-mobile-fontFamily: #{$font-family};
        --typography-subtext-regular-desktop-fontFamily: #{$font-family};
        --typography-subtext-regular-tablet-fontFamily: #{$font-family};
        --typography-subtext-regular-mobile-fontFamily: #{$font-family};
        --typography-subtext-strong-desktop-fontFamily: #{$font-family};
        --typography-subtext-strong-tablet-fontFamily: #{$font-family};
        --typography-subtext-strong-mobile-fontFamily: #{$font-family};
        --typography-link-1-regular-desktop-fontFamily: #{$font-family};
        --typography-link-1-regular-tablet-fontFamily: #{$font-family};
        --typography-link-1-regular-mobile-fontFamily: #{$font-family};
        --typography-link-1-strong-desktop-fontFamily: #{$font-family};
        --typography-link-1-strong-tablet-fontFamily: #{$font-family};
        --typography-link-1-strong-mobile-fontFamily: #{$font-family};
        --typography-link-2-regular-desktop-fontFamily: #{$font-family};
        --typography-link-2-regular-tablet-fontFamily: #{$font-family};
        --typography-link-2-regular-mobile-fontFamily: #{$font-family};
        --typography-link-2-strong-desktop-fontFamily: #{$font-family};
        --typography-link-2-strong-tablet-fontFamily: #{$font-family};
        --typography-link-2-strong-mobile-fontFamily: #{$font-family};
        --typography-button-desktop-fontFamily: #{$font-family};
        --typography-button-tablet-fontFamily: #{$font-family};
        --typography-button-mobile-fontFamily: #{$font-family};
    }

    .skip-link {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;

        &:focus {
            position: static;
            width: auto;
            height: auto;
        }
    }
    
    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }
}
